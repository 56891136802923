<template>
	<div class="posibox">
		<div class="con-tab">
			<div class="img_box"><img :src="urlImg + logo" /></div>
			<img class="caidan" @click="barshow" src="../../assets/img/images/caidan.png" />
			<!-- 导航弹框 -->
			<div class="innavbar" v-if="navbox">
				<div class="intop">
					<img class="inlogo" :src="urlImg + logo" />
					<img class="caipic" @click="barhide" src="../../assets/img/images/caidan.png" />
				</div>
				<div class="intandan" :class="{ tabP: currentIndex == index }" @click="tabClick(item, index)"
					v-for="(item, index) in tabList" :key="index">
					{{ item.nav_name }}
				</div>
			</div>
			<div class="tab" :class="{ tabP: currentIndex == index }" @click="tabClick(item, index)"
				@mouseover="mouseList(item, index)" @mouseleave="upList" @mouseout="removeActive()"
				v-for="(item, index) in tabList" :key="index">
				{{ item.nav_name }}
				<img class="sanpic" v-if="item.chose" src="../../assets/img/images/san_05.png" />
				<img class="fire" v-if="item.chose" src="../../assets/img/images/fire_03.png" />
			</div>
			<div class="zhi-nav-box" @mouseover="chanActive()" @mouseout="chanremove()" v-show="chanshow">
				<div class="zhielft">
					<div class="zhiul">
						<div class="zhili">
							<div class="txt">找专利</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
						<div class="zhili" @click="zhaoXq(1)">
							<div class="txt">找版权</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
						<div class="zhili" @click="zhaoXq(2)">
							<div class="txt">找商标</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
						<div class="zhili" @click="zhaoXq(3)">
							<div class="txt">找成果</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
					</div>
				</div>
				<div class="zhiyou">
					<div class="zhinei">
						<div class="zhitop">
							<div class="zhititle">
								热门推荐
							</div>
							<div class="zhiys">
								<div class="cetabboxa">
									<div class="tabli" :class="{ tabactive: sousuoindex == index }"
										v-for="(item, index) in tablistact" :key="index" @click="seartab(index)">
										{{ item.name }}
									</div>
								</div>
								<div class="zhisou">
									<input type="text" v-model="searchact" class="zhiput" placeholder="请输入要搜索的内容">
									<div class="zhisub" @click="submitForm">
										搜索
									</div>
								</div>
							</div>

						</div>
						<div class="tiaoul">
							<div class="tiaolia" v-for="(item , index) in WisdomLista" :key="index"
								@click="xq(item.id)">
								{{item.name}}
							</div>
						</div>
					</div>
				</div>
			</div>



			<div class="zhi-nav-box" @mouseover="kefuActive()" @mouseout="kefuremove()" v-show="kefushow">
				<div class="zhielft">
					<div class="zhiul">
						<div class="zhili">
							<div class="txt">找服务</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
						<div class="zhili" @click="goToxq(1)">
							<div class="txt">找人才</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
						<div class="zhili" @click="goToxq(2)">
							<div class="txt">找设备</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
						<div class="zhili" @click="goToxq(3)">
							<div class="txt">找政策</div>
							<img class="zhipic" src="../../assets/img/images/navrr_03.png" />
						</div>
					</div>
				</div>
				<div class="zhiyou">
					<div class="zhinei" style="padding-top: 15px;">
						<div class="zhitop">
							<div class="zhititle">
								热门推荐
							</div>
							<div class="zhiys">
								<div class="cetabboxa">
									<div class="tabli" :class="{ tabactive: sousuoindex == index }"
										v-for="(item, index) in tablistact" :key="index" @click="seartab(index)">
										{{ item.name }}
									</div>
								</div>
								<div class="zhisou">
									<input type="text" v-model="searchact" class="zhiput" placeholder="请输入要搜索的内容">
									<div class="zhisub" @click="submitForm">
										搜索
									</div>
								</div>
							</div>
						</div>
						<div class="tiaoul">
							<a href="http://www.kehui.cloud/#/s_xq?id=57">
								<div class="tiaoli">
									高新技术企业
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=101">
								<div class="tiaoli">
									知识产权增资实缴
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=30">
								<div class="tiaoli">
									创新创业培训
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=95">
								<div class="tiaoli">
									科研课题设计
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=38">
								<div class="tiaoli">
									科技成果评价
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=31">
								<div class="tiaoli">
									可行性研究报告
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=74">
								<div class="tiaoli">
									商业计划书
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=81">
								<div class="tiaoli">
									水土保持方案评估
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=46">
								<div class="tiaoli">
									科技计划项目
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=48">
								<div class="tiaoli">
									工业发展专项
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=59">
								<div class="tiaoli">
									专家院士工作站
								</div>
							</a>
							<a href="http://www.kehui.cloud/#/s_xq?id=106">
								<div class="tiaoli">
									专精特新企业
								</div>
							</a>
						</div>

						<div class="fuul">
							<div class="fuli">
								<img class="pic" src="../../assets/img/images/qqa_03.png" />
								<div class="you">
									<div class="futitle">
										服务商入驻
									</div>
									<div class="lizhu" @click="goToruzhu">
										<div class="litxt">立即入住</div>
										<img class="lipic" src="../../assets/img/images/navrr_03.png" />
									</div>
								</div>
							</div>
							<div class="fuli">
								<img class="pic" src="../../assets/img/images/qqb_03.png" />
								<div class="you">
									<div class="futitle">
										注册技术经理人
									</div>
									<div class="lizhu" @click="goTomanager">
										<div class="litxt">立即注册</div>
										<img class="lipic" src="../../assets/img/images/navrr_03.png" />
									</div>
								</div>
							</div>
							<div class="fuli">
								<img class="pic" src="../../assets/img/images/qqc_03.png" />
								<div class="you">
									<div class="futitle">
										需求发布入口
									</div>
									<div class="lizhu" @click="goToxufa">
										<div class="litxt">点击发布</div>
										<img class="lipic" src="../../assets/img/images/navrr_03.png" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="zhi-nav-box" @mouseover="zhengActive()" @mouseout="zhengremove()" v-show="zhengshow">
				<div class="shubox">
					<div class="shuli">
						<img class="shupic" src="../../assets/img/images/zza_03.png" />
						<div class="shutitle">
							政策大汇集
						</div>
						<div class="seeqing" @click="zmore">查看详情</div>
					</div>
					<div class="shuli">
						<img class="shupic" src="../../assets/img/images/zzb_03.png" />
						<div class="shutitle">
							重大行政事项及投资决策后评估
						</div>
						<div class="seeqing" @click="seezhong">查看详情</div>
					</div>
					<div class="shuli">
						<img class="shupic" src="../../assets/img/images/zzc_03.png" />
						<div class="shutitle">
							举办各类科技活动
						</div>
						<div class="seeqing" @click="seeju">查看详情</div>
					</div>
					<div class="shuli">
						<img class="shupic" src="../../assets/img/images/zzd_03.png" />
						<div class="shutitle">
							创新企业培育及运行监测
						</div>
						<div class="seeqing" @click="seechuang">查看详情</div>
					</div>
					<div class="shuli">
						<img class="shupic" src="../../assets/img/images/zze_03.png" />
						<div class="shutitle">
							开发政府性科技平台
						</div>
						<div class="seeqing" @click="seekai">查看详情</div>
					</div>
					<div class="shuli">
						<img class="shupic" src="../../assets/img/images/zzf_03.png" />
						<div class="shutitle">
							搭建地方科技专家库
						</div>
						<div class="seeqing" @click="seeda">查看详情</div>
					</div>
				</div>
			</div>
			<div class="header-nav-box clearfix" @mouseover="changeActive()" @mouseout="removeActive()" v-show="show">
				<div class="header-nav-box-in">
					<div class="nav-type-box brand">
						<h3>技术转移优选</h3>
						<ul>
							<li v-for="item in tList" :key="item.id">
								<h4>{{ item.name }}</h4>
								<div class="products clearfix">
									<router-link v-for="i in item.children" :key="i.id"
										:to="{ path: 's_xq', query: { id: i.id } }"
										target="_blank">{{ i.name }}</router-link>
								</div>
							</li>
						</ul>
					</div>
					<div class="nav-type-box patent">
						<h3>全过程咨询</h3>
						<ul>
							<li v-for="item in zList" :key="item.id">
								<h4>{{ item.name }}</h4>
								<div class="products clearfix">
									<router-link v-for="ie in item.children" :key="ie.id"
										:to="{ path: 's_xq', query: { id: ie.id } }"
										target="_blank">{{ ie.name }}</router-link>
								</div>
							</li>
						</ul>
					</div>
					<div class="nav-type-box copyright">
						<h3>政策应用优选</h3>
						<ul>
							<li v-for="item in yList" :key="item.id">
								<h4>{{ item.name }}</h4>
								<div class="products clearfix">
									<router-link v-for="it in item.children" :key="it.id"
										:to="{ path: 's_xq', query: { id: it.id } }"
										target="_blank">{{ it.name }}</router-link>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Logo
	} from '../../common/js/api';
	import {
		urlImg
	} from '../../common/js/url';
	import {
		Wisdom
	} from "../../common/js/api";
	export default {
		name: 'ConTab',
		data() {
			return {
				WisdomLista: [],
				currentIndex: sessionStorage.getItem('cur') || 0,
				tabList: [{
						nav_name: '首页',
						nav_url: '/home',
						chose: false
					},
					{
						nav_name: '科服云',
						nav_url: '/about',
						chose: true
					},
					{
						nav_name: '政府云',
						nav_url: '/government',
						chose: true
					},
					{
						nav_name: '智产云',
						nav_url: '/wisdom',
						chose: true
					},
					{
						nav_name: '企业云',
						nav_url: '/enterprise',
						chose: false
					},
					{
						nav_name: '机构云',
						nav_url: '/organizati',
						chose: false
					},
					{
						nav_name: '人才云',
						nav_url: '/humancloud',
						chose: false
					},
					{
						nav_name: '创新工具',
						nav_url: '/innovation',
						chose: false
					}
					// {"nav_name":"科创学院",
					//     "nav_url": "/home"
					// },
				], //获取列表数据
				navbox: false,
				// 手机端弹框
				show: false,
				logo: '',
				urlImg: '',
				tList: [{
						name: '知产评估交易',
						children: [{
								name: '依托系统自主测评',
								id: 105
							},
							{
								name: '专业资质机构评估',
								id: 104
							},
							{
								name: '知识产权交易',
								id: 103
							},
							{
								name: '知识产权变更登记',
								id: 40
							}
						]
					},
					{
						name: '科技金融',
						children: [{
								name: '专利权质押登记',
								id: 41
							},
							{
								name: '资产评估与重组策划',
								id: 43
							},
							{
								name: '融资风险评估',
								id: 44
							},
							{
								name: '信贷申请汇编资料',
								id: 102
							},
							{
								name: '尽职调查',
								id: 45
							},
							{
								name: '知识产权增资实缴',
								id: 101
							},
							{
								name: '研发费用加计扣除',
								id: 100
							},
							{
								name: '新三板上市辅导',
								id: 99
							},
							{
								name: '天使投资',
								id: 98
							},
							{
								name: '贷款贴息申办',
								id: 107
							},
							{
								name: '财税策划',
								id: 97
							}
						]
					},
					{
						name: '产学研合作',
						children: [{
								name: '共建研究机构',
								id: 25
							},
							{
								name: '技术难题攻关',
								id: 26
							},
							{
								name: '荣誉资质挂牌',
								id: 27
							},
							{
								name: '技术人才委培',
								id: 28
							},
							{
								name: '举办交流活动',
								id: 29
							},
							{
								name: '创新创业培训',
								id: 30
							}
						]
					},
					{
						name: '技转全链条服务',
						children: [{
								name: '检验检测',
								id: 32
							},
							{
								name: '型式试验',
								id: 33
							},
							{
								name: '战略情报研究',
								id: 34
							},
							{
								name: '课题可行性研究',
								id: 96
							},
							{
								name: '科研课题设计',
								id: 95
							},
							{
								name: '技术线路规划',
								id: 35
							},
							{
								name: '研发体系规划',
								id: 36
							},
							{
								name: '行业分析报告',
								id: 37
							},
							{
								name: '科技成果评价',
								id: 38
							},
							{
								name: '科技成果登记',
								id: 94
							},
							{
								name: '技术合同登记',
								id: 39
							}
						]
					}
				],
				zList: [{
						name: '前期研究',
						children: [{
								name: '可行性研究报告',
								id: 31
							},
							{
								name: '项目建议书',
								id: 50
							},
							{
								name: '商业计划书',
								id: 74
							},
							{
								name: '项目实施方案',
								id: 75
							},
							{
								name: '退城进园方案',
								id: 76
							}
						]
					},
					{
						name: '并联评价',
						children: [{
								name: '节能评估',
								id: 77
							},
							{
								name: '地质灾害危险性评估',
								id: 78
							},
							{
								name: '行洪论证',
								id: 79
							},
							{
								name: '水土保持方案评估',
								id: 81
							},
							{
								name: '选址论证',
								id: 93
							},
							{
								name: '环境影响评价',
								id: 82
							},
							{
								name: '安全评价',
								id: 83
							},
							{
								name: '职业病危害评价',
								id: 84
							},
							{
								name: '移民安置方案评估',
								id: 85
							},
							{
								name: '交通影响评价',
								id: 92
							},
							{
								name: '重大投资项目社会稳定风险评估',
								id: 91
							},
							{
								name: '节地评价',
								id: 90
							},
							{
								name: '节水论证',
								id: 89
							},
							{
								name: '生态资源修复方案',
								id: 88
							},
							{
								name: '能源审计',
								id: 86
							},
							{
								name: '竣工验收',
								id: 87
							}
						]
					},
					{
						name: '规划设计',
						children: [{
								name: '概念性设计',
								id: 72
							},
							{
								name: '工程设计咨询',
								id: 73
							},
							{
								name: '产业规划咨询',
								id: 71
							},
							{
								name: '全过程规划咨询',
								id: 80
							}
						]
					}
				],
				yList: [{
						name: '评定与认定',
						children: [{
								name: '职称评定',
								id: 63
							},
							{
								name: '工程技术中心',
								id: 54
							},
							{
								name: '工程技术研究院',
								id: 62
							},
							{
								name: '专家院士工作站',
								id: 59
							},
							{
								name: '高新技术企业',
								id: 57
							},
							{
								name: '科技型中小企业',
								id: 51
							},
							{
								name: '双软认证',
								id: 49
							}
						]
					},
					{
						name: '资质体系',
						children: [{
								name: '专精特新',
								id: 106
							},
							{
								name: '高成长型',
								id: 61
							},
							{
								name: '行业小巨人申报',
								id: 53
							},
							{
								name: '三体系认证',
								id: 55
							},
							{
								name: '知识产权贯标',
								id: 56
							},
							{
								name: '两化融合',
								id: 60
							},
							{
								name: '各类生产许可',
								id: 58
							},
							{
								name: '规上申报统计',
								id: 64
							}
						]
					},
					{
						name: '申报与验收',
						children: [{
								name: '专项申报指导',
								id: 65
							},
							{
								name: '验收指导',
								id: 66
							},
							{
								name: '发改工信专项',
								id: 67
							},
							{
								name: '重大课题',
								id: 68
							},
							{
								name: '转移支付',
								id: 69
							},
							{
								name: '切块项目',
								id: 70
							},
							{
								name: '中小微企业发展规划',
								id: 47
							},
							{
								name: '工业发展专项',
								id: 48
							},
							{
								name: '科技计划项目',
								id: 46
							}
						]
					}
				],
				chanpinList: [{
						name: '找专利'
					},
					{
						name: '找版权'
					},
					{
						name: '找商标'
					},
					{
						name: '找成果'
					}
				],
				chanChose: 0,
				chanshow: false,
				kefuList: [{
						name: '找服务'
					},
					{
						name: '找人才'
					},
					{
						name: '找设备'
					},
					{
						name: '找政策'
					}
				],
				kefuChose: 0,
				kefushow: false,

				zhengshow: false,
				tablistact: [{
					id: 1,
					name: '政策'
				}, {
					id: 2,
					name: '活动'
				}, {
					id: 3,
					name: '智库'
				}, {
					id: 4,
					name: '成果'
				}, {
					id: 5,
					name: '专利'
				}],
				sousuoindex: 0,
				searchact: ''
			};
		},
		created() {
			this.getLogo();
			this.urlImg = urlImg();
			this.getWisdom()
		},
		mounted() {},
		methods: {


			// 搜索切换
			seartab(index) {
				this.sousuoindex = index;
			},

			submitForm() {
				if (this.searchact.trim() == "") {
					this.$message.error("请输入关键字");
				} else {
					if (this.sousuoindex == 1) {
						let routeData = this.$router.resolve({
							path: "/member8",
							query: {
								keyword: this.searchact,
							},
						});
						window.open(routeData.href, "_blank");
					} else if (this.sousuoindex == 2) {
						let routeData = this.$router.resolve({
							path: "/member7",
							query: {
								keyword: this.searchact,
							},
						});
						window.open(routeData.href, "_blank");
					} else if (this.sousuoindex == 3) {
						let routeData = this.$router.resolve({
							path: "/member3",
							query: {
								keyword: this.searchact,
							},
						});
						window.open(routeData.href, "_blank");
					} else if (this.sousuoindex == 4) {
						let routeData = this.$router.resolve({
							path: "Patent",
							query: {
								keyword: this.searchact,
							},
						});
						window.open(routeData.href, "_blank");
					} else {
						let routeData = this.$router.resolve({
							path: "/member5",
							query: {
								keyword: this.searchact,
							},
						});
						window.open(routeData.href, "_blank");
					}
				}
			},

		xq(id) {
			this.$router.push({
				path: "/ZReleaseXQ",
				query: {
					id: id,
				},
			});
		},
		getWisdom() {
			Wisdom()
				.then((res) => {
					this.WisdomLista = res.data.one.slice(0, 8);
				})
				.catch(() => {});
		},
		zmore() {
			this.$router.push({
				path: "/member5",
			});
		},
		seezhong() {
			this.$router.push({
				path: "/tab?id=1",
			});
		},
		seeju() {
			this.$router.push({
				path: "tack?id=9",
			});
		},
		seechuang() {
			this.$router.push({
				path: "tack?id=8",
			});
		},
		seekai() {
			this.$router.push({
				path: "/tack?id=7",
			});
		},
		seeda() {
			this.$router.push({
				path: "/tack?id=6",
			});
		},
		// 注册经理人
		goTomanager() {
			this.$router.push({
				path: '/apply',
			});
		},
		// 需求发布
		goToxufa() {
			this.$router.push({
				path: '/need',
			});
		},
		// 找
		goToxq(id) {
			if (id == 1) {
				this.$router.push({
					path: '/Recruit',
				});
			} else if (id == 2) {
				this.$router.push({
					path: '/device',
				});
			} else if (id == 3) {
				this.$router.push({
					path: '/member5/test5-1',
				});
			}
		},
		zhaoXq(id) {
			if (id == 1) {
				this.$router.push({
					path: '/Patent?i=1',
				});
			} else if (id == 2) {
				this.$router.push({
					path: '/Patent?i=2',
				});
			} else if (id == 3) {
				this.$router.push({
					path: '/member3/test3-1',
				});
			}
		},
		// 服务商入住
		goToruzhu() {
			this.$router.push({
				path: '/service_s',
			});
		},
		// 手机端弹框
		barshow() {
			this.navbox = true
		},
		barhide() {
			this.navbox = false
		},
		// logo
		getLogo() {
			Logo({})
				.then(res => {
					this.logo = res.data.logo;
				})
				.catch(() => {});
		},
		tabClick(item, index) {
			//点击
			this.currentIndex = index;
			sessionStorage.setItem('cur', this.currentIndex);
			this.$router.push({
				path: item.nav_url
			});
		},
		changeActive() {
			this.show = true;
		},
		mouseList(item, index) {
			//鼠标移入时
			this.currentIndex = index;
			if (this.currentIndex == 0) {
				this.show = true;
				this.kefushow = false;
				this.zhengshow = false;
				this.chanshow = false;
			}
			if (this.currentIndex == 1) {
				this.kefushow = true;
				this.show = false;
				this.zhengshow = false;
				this.chanshow = false;
			}
			if (this.currentIndex == 2) {
				this.zhengshow = true;
				this.kefushow = false;
				this.show = false;
				this.chanshow = false;
			}
			if (this.currentIndex == 3) {
				this.chanshow = true;
				this.zhengshow = false;
				this.kefushow = false;
				this.show = false;
			}
		},
		removeActive() {
			this.show = false;
		},
		upList() {
			//鼠标离开时
			this.currentIndex = sessionStorage.getItem('cur') || 0;
		},
		chanChange(index) {
			this.chanChose = index
		},
		// 智产
		chanActive() {
			this.chanshow = true;
			this.kefushow = false;
		},

		chanremove() {
			this.chanshow = false;
		},
		// 科服
		kefuActive() {
			this.kefushow = true;
			this.chanshow = false;
		},

		kefuremove() {
			this.kefushow = false;
		},
		kefuChange(index) {
			this.kefuChose = index
		},
		// 政府
		zhengActive() {
			this.zhengshow = true;
		},
		zhengremove() {
			this.zhengshow = false;
		},
	}
	};
</script>

<style scoped lang="less">
	.posibox {
		position: fixed;
		left: 0;
		background-color: #ffffff;
		top: 42px;
		width: 100%;
		z-index: 995;
		box-shadow: 0 2px 4px #dddddd;
	}

	.con-tab {

		display: flex;
		height: 95px;
		width: 1400px;
		margin: 0 auto;
		font-size: 15px;
		color: #101010;
		overflow: hidden;
		align-items: center;
		vertical-align: bottom;

		.img_box {
			width: 230px;
			height: 70px;
			vertical-align: bottom;
			margin-right: 55px;

			img {
				vertical-align: bottom;
				width: 230px;
				height: 70px;
			}
		}

		.tab {
			position: relative;
			width: 99px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			cursor: pointer;
			border-radius: 5px 5px 0px 0px;
			vertical-align: bottom;
			margin-right: 25px;

			.fire {
				width: 12px;
				position: absolute;
				left: 5px;
				top: 8px;
				height: 16px;
			}

			.sanpic {
				width: 9px;
				height: 5px;
				position: absolute;
				right: 13px;
				top: 15px;
			}
		}

		.tab:not(:first-child) {
			margin-top: 10px;
		}

		.tabP {
			font-size: 15px;
			width: 99px;
			height: 36px;
			color: #3892eb;
		}
	}

	.header-nav-box {
		position: fixed !important;
		top: 105px;
		left: 0;
		padding: 5px 40px 20px;
		width: 100% !important;
		// height: calc(100% - 80px) !important;
		min-width: 1200px;
		color: #222;
		line-height: 1em;
		background-color: #fff;
		border-top: 1px solid #f0f0f2;
		box-shadow: 0px 4px 6px 0px rgb(44 48 71 / 20%);
		z-index: 999999;
		margin-top: 0 !important;
	}

	.header-nav-box .nav-type-box {
		float: left;
		margin-right: 60px;
		width: 320px;
	}

	.header-nav-box .nav-type-box:last-of-type {
		margin-right: 0;
	}

	.header-nav-box h3 {
		margin-bottom: 20px;
		padding-left: 24px;
		font-size: 14px;
		font-weight: bold;
		line-height: 3em;
		border-bottom: 1px solid #e9ebef;
		background-repeat: no-repeat;
		background-position: 0 center;
		background-size: 16px;
	}

	// .header-nav-box .nav-type-box.brand h3 {
	//     background-image: url(../images/icons/icon-r.png);
	// }

	// .header-nav-box .nav-type-box.patent h3 {
	//     background-image: url(../images/icons/icon-p.png);
	// }

	// .header-nav-box .nav-type-box.copyright h3 {
	//     background-image: url(../images/icons/icon-c.png);
	// }

	.header-nav-box h4 {
		margin-bottom: 16px;
		color: #999;
		font-size: 13px;
	}

	.header-nav-box li {
		margin-bottom: 8px;
	}

	.header-nav-box li a {
		float: left;
		display: block;
		margin-right: 10px;
		margin-bottom: 14px;
		padding-right: 10px;
		color: #000;
		font-size: 13px;
		border-right: 1px solid #e8ebef;
	}

	.header-nav-box li a:hover {
		color: #ff7200;
	}

	.zhi-nav-box {
		position: fixed !important;
		top: 137px;
		left: 0;
		width: 100% !important;
		min-width: 1400px;
		color: #222;
		line-height: 1em;
		background-color: #fff;
		border-top: 1px solid #f0f0f2;
		box-shadow: 0px 4px 6px 0px rgb(44 48 71 / 20%);
		z-index: 999999;
		margin-top: 0 !important;
		display: flex;
		align-items: stretch;

		.shubox {
			width: 1400px;
			margin: 0 auto;
			padding: 50px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.shuli {
				width: 215px;
				height: 260px;
				border-radius: 3px;
				background-color: #f2f5ff;
				text-align: center;
				box-shadow: 0 0 6px #dddddd;
				position: relative;
				transition: all 0.5s;

				.shupic {
					width: 39px;
					height: 34px;
					margin: 30px auto;
				}

				.shutitle {
					font-size: 16px;
					color: #2b3151;
					text-align: left;
					padding: 0 18px;
					line-height: 25px;
				}

				.seeqing {
					width: 90px;
					height: 30px;
					border: 1px solid #0289f4;
					text-align: center;
					line-height: 30px;
					cursor: pointer;
					position: absolute;
					font-size: 16px;
					color: #0289f4;
					left: 50%;
					margin-left: -45px;
					bottom: 30px;
					border-radius: 3px;
					transition: all 0.5s;
				}
			}

			.shuli:hover {
				transform: translateY(-5%);

				.seeqing {
					background-color: #0289f4;
					color: #ffffff;
				}
			}
		}

		.zhielft {
			width: 30%;
			background-color: #f2f5ff;
			flex-shrink: 0;
			display: flex;
			justify-content: flex-end;

			.zhiul {
				width: 210px;
				padding-top: 7px;
				padding-bottom: 100px;

				.zhili {
					width: 100%;
					text-align: right;
					position: relative;
					height: 50px;
					cursor: pointer;

					.txt {
						font-size: 14px;
						line-height: 50px;
						padding-right: 45px;
						color: #2b3151;
					}

					.zhipic {
						width: 15px;
						height: 4px;
						position: absolute;
						top: 23px;
						right: 15px;
						display: none;
					}
				}

				.zhili:hover {
					background-color: #daeafe;

					.txt {
						color: #0289f4;
					}

					.zhipic {
						display: block;
					}
				}
			}
		}

		.zhiyou {
			width: 70%;
			flex-shrink: 0;

			.zhinei {
				width: 930px;
				padding-left: 50px;
				padding-top: 40px;

				.zhitop {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-bottom: 35px;

					.zhititle {
						font-weight: bold;
						font-size: 16px;
						color: #2b3151;
					}

					.zhiys {
						flex-shrink: 0;
					}

					.zhisou {
						width: 550px;
						height: 36px;
						border: 1px solid #0289f4;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.zhiput {
							padding: 0 15px;
							flex-grow: 1;
							font-size: 14px;
							color: #2b3151;
						}

						.zhisub {
							width: 70px;
							flex-shrink: 0;
							height: 100%;
							line-height: 35px;
							text-align: center;
							color: #ffffff;
							font-size: 16px;
							cursor: pointer;
							background-color: #0289f4;
						}
					}
				}

				.tiaoul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.tiaoli {
						cursor: pointer;
						width: 210px;
						font-size: 14px;
						margin-bottom: 10px;
						color: #888888;
					}

					.tiaolia {
						cursor: pointer;
						width: 280px;
						font-size: 14px;
						margin-bottom: 20px;
						color: #888888;
					}

					.tiaoli:hover {
						color: #0289f4;
					}
				}

				.fuul {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 40px;
					border-top: 1px solid #eeeeee;

					.fuli {
						display: flex;
						align-items: flex-start;

						.pic {
							width: 40rpx;
							margin-right: 10px;
						}

						.futitle {
							font-size: 15px;
							color: #2b3151;
							padding-bottom: 15px;
						}

						.lizhu {
							width: 127px;
							height: 34px;
							border: 1px solid #0289f4;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							transition: all 0.5s;

							.litxt {
								font-size: 13px;
								color: #0289f4;
								padding-right: 7px;
							}

							.lipic {
								width: 15px;
								height: 4px;
							}
						}

						.lizhu:hover {
							transform: translateX(5%);
						}
					}
				}
			}
		}
	}

	.caidan {
		width: 4rem;
		height: 4rem;
		display: none;
	}

	.cetabboxa {
		display: flex;
		align-items: center;
		padding-bottom: 10px;
		padding-left: 15px;

		.tabli {
			font-size: 14px;
			color: #333333;
			margin-right: 45px;
			cursor: pointer;
		}

		.tabactive {
			color: #ff7200;
		}
	}

	@media screen and (max-width: 1200px) {
		.posibox {
			position: fixed;
			left: 0;
			background-color: #ffffff;
			top: 7.9rem;
			width: 100%;
			z-index: 999;
			box-shadow: 0 2px 4px #dddddd;
		}

		.caidan {
			display: block;
		}

		.con-tab {
			height: 7rem;
			width: 98%;
			margin: 0 auto;
			overflow-x: hidden;
			justify-content: space-between;
		}

		.con-tab .tab {
			display: none;
		}

		.con-tab .img_box {
			width: 18rem;
			height: 6rem;
		}

		.con-tab .img_box img {
			width: 18rem;
			height: 6rem;
		}

		#app {
			overflow: hidden;
			width: 100%;
		}

		.innavbar {
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 9999999999999999999;
		}

		.intop {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem;

			.inlogo {
				width: 18rem;
				height: 6rem;
			}

			.caipic {
				width: 4rem;
				height: 4rem;
			}
		}

		.intandan {
			padding: 2rem 1rem;
			width: 100% !important;
			font-size: 2rem;
			height: auto !important;
			border-bottom: 5px dashed #eeeeee;
		}

		.tabP {
			font-size: 2rem !important;

		}
	}
</style>